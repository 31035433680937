<template>
  <div class="edito">
    <br />
    <br />
    <p>
      Notre opération n'a pas encore débuté.<br /><br />
      Rendez vous le 01/04/2021.<br />
    </p>
    <br />
    <br />
  </div>
</template>
<script>
export default {
  name: "Waiting",
};
</script>
